@import '@hiredigital/ui/variables';

a.button {
  padding: $btn-padding-4;
  transition: transform 0.2s $cubic, box-shadow 0.2s $cubic !important;
  box-shadow: $shadow-30;
  margin: 0;

  & ~ & {
    margin-left: 20px;
  }

  @include for-size(phone-only) {
    width: 100%;
    min-width: 0;

    & ~ & {
      margin-left: 0;
    }
  }

  &:hover {
    box-shadow: $shadow-50;
    transform: translateY(-3px);
  }
}
